/* eslint-disable react/jsx-props-no-spreading */
import React, { ChangeEvent, FocusEventHandler } from 'react';
import { Input, InputProps, Typography } from 'antd';
import { useFela } from 'react-fela';
import {
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

import { FelaFunction, FelaStyle } from '../../../fela';
import colors from '../../colors';

const { Text } = Typography;

export type FormInputProps<T extends FieldValues> = UseControllerProps<T> &
  InputProps & {
    formatter?: (value: string) => string;
    showErrorMessage?: boolean;
    blurWithError?: boolean;
  };

export default function FormInput<T extends FieldValues>({
  name,
  control,
  className,
  formatter = (value: string) => value,
  onChange = () => {},
  onBlur = () => {},
  showErrorMessage = true,
  blurWithError = false,
  ...fieldProps
}: FormInputProps<T>) {
  const {
    field: {
      ref,
      onChange: onFieldChange,
      onBlur: onFieldBlur,
      value,
      ...otherProps
    },
    formState: { errors },
  } = useController<T>({ name, control });

  const error = errors[name];
  const inputStatus = error ? 'error' : undefined;
  const errorType = error?.type;

  const { css } = useFela({ errorType });

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e);
    onFieldChange({ ...e, target: { value: formatter(e.target.value) } });
  };

  const onInputBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    if (!error || blurWithError) onBlur(e);
    onFieldBlur();
  };

  return (
    <>
      <Input
        ref={ref}
        className={`${className} ${css(errorBorderStyle)}`}
        status={inputStatus}
        onChange={onInputChange}
        onBlur={onInputBlur}
        value={value}
        {...fieldProps}
        {...otherProps}
      />
      {showErrorMessage && (
        <Text className={css(errorTextStyle)} type="danger">
          {error?.message}
        </Text>
      )}
    </>
  );
}

const errorBorderStyle: FelaFunction<{ errorType?: string }> = ({
  errorType,
}) => {
  if (!errorType) {
    return {};
  }

  if (errorType === 'required-warning' || errorType === 'required') {
    return {
      borderColor: colors.formItem.warning,
    };
  }

  return {
    borderColor: colors.formItem.error,
  };
};

const errorTextStyle: FelaFunction<{ errorType?: string }> = ({
  errorType,
}) => {
  if (errorType === 'required-warning' || errorType === 'required') {
    return {
      ...styles.errorText,
      color: colors.formItem.warning,
    };
  }

  return {
    ...styles.errorText,
    color: colors.formItem.error,
  };
};

const styles: FelaStyle = {
  errorText: {
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '1.375rem',
    margin: '0',
  },
};
